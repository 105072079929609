export const MESSAGES_LIST = [
    {
        content: 'Hi, how I can help you with your tax questions?',
        role: 'assistant',
    },
];

export const ASSISTANT_LOADING_MESSAGE = {
    content: 'One second please.',
    role: 'assistant',
};
